import { useEffect, useMemo } from 'react'
import { mapConfig } from '../../config'
import { useGoogleApi } from '../mapLoader'

export const getCirclePath = (r) =>
	`M ${-r} 0 a ${r},${r} 0 1,1 ${r * 2},0 a ${r},${r} 0 1,1 -${r * 2},0`

const circle4 = getCirclePath(4)

export const createMarkerIcon = (coreApi, color = 'red') => ({
	path: circle4,
	fillColor: color,
	fillOpacity: 1,
	strokeWeight: 0,
	rotation: 0,
	scale: 1,
	anchor: new coreApi.Point(0, 0)
})

const size = 156543.03392

export const scaleFromZoom = (zoom, lat) =>
	size * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom)

export const zoomFromScale = (scale, lat) =>
	Math.log2(size * Math.cos(lat * Math.PI / 180) / scale)

export const useMap = (mapRef) => {
	const mapsApi = useGoogleApi('maps')

	return useMemo(() => {
		return mapsApi == null
			? null
			: new mapsApi.Map(mapRef.current, mapConfig)
	}, [mapsApi])
}

export const usePolyline = (map, path, color = '#FF0') => {
	const mapsApi = useGoogleApi('maps')

	useEffect(() => {
		if (path == null || mapsApi == null || map == null) {
			return
		}

		const polyline = new mapsApi.Polyline({
			path,
			geodesic: true,
			strokeColor: color,
			strokeOpacity: 1,
			strokeWeight: 3,
			zIndex: 3,
			map
		})

		return () => {
			polyline.setMap(null)
		}
	}, [path, mapsApi, map])
}

export const useMarkerPath = (map, path, color, onClick) => {
	const coreApi = useGoogleApi('core')
	const mapsApi = useGoogleApi('maps')
	const markerApi = useGoogleApi('marker')

	useEffect(() => {
		if (path == null || coreApi == null || mapsApi == null || markerApi == null || map == null) {
			return
		}

		const markers = []

		path.forEach((position, i) => {
			if (position == null) {
				return
			}

			const marker = new markerApi.Marker({
				position,
				icon: createMarkerIcon(coreApi, color),
				map
			})

			if (onClick != null) {
				marker.addListener('click', () => {
					onClick(i)
				})
			}

			markers.push(marker)
		})

		return () => {
			markers.forEach((marker) => {
				marker.setMap(null)
			})
		}
	}, [path, coreApi, mapsApi, markerApi, map])
}
