import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useState } from 'react'
import { mapApiKey } from '../config'

export const mapLoader = new Loader({
	apiKey: mapApiKey,
	version: 'weekly'
})

export const useGoogleApi = (name) => {
	const [api, setApi] = useState(null)

	useEffect(() => {
		mapLoader.importLibrary(name).then(setApi)
	}, [name])

	return api
}
