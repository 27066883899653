import exifr from 'exifr'
import { useEffect, useState } from 'react'
import Spinner from '../common/spinner/Spinner'
import { flightFrames } from '../routing'
import { mapCsv, readCsv } from './csv'
import DbContext from './DbContext'
import { useDropZone } from './dropZone'
import { dbPut } from './indexedDB'

// useEffect(() => {
// 	return async () => {
// 		const db = await dbPromise
// 		db.close()
// 	}
// }, [dbPromise])

const imageTypes = new Set(['png', 'jpg', 'jpeg', 'svg', 'gif', 'jfif', 'pjpeg', 'pjp'])

const DataHandler = ({
	children,
	router,
	dbPromise
}) => {
	const [parsing, setParsing] = useState(false)
	const {
		state,
		files,
		// firstName, // TODO: use as a flight name
		error,
		reset
	} = useDropZone()

	useEffect(() => {
		if (files == null) {
			return
		}
		setParsing(true)
		const flightId = crypto.randomUUID()
		const flightPath = 'local-' + flightId
		let meta = null
		const frames = []
		const imagePromises = []
		const promises = files.map(async (file) => {
			const { name } = file
			if (name === 'coordinates_time.txt') {
				const rows = await readCsv(file)
				meta = mapCsv(rows)
				return
			}
			const match = /\.(\w+)$/.exec(name)
			if (match == null || !imageTypes.has(match[1].toLowerCase())) {
				return
			}
			const info = await exifr.parse(file)
			const frameId = crypto.randomUUID()
			frames.push({
				id: 'local-' + frameId,
				approved: false,
				width: info.ImageWidth,
				height: info.ImageHeight,
				azimuth: 0,
				latitude: 0,
				longitude: 0,
				flightId: flightPath, // : entry.fly_id,
				imageName: name,
				imageUrl: null,
				location: {
					lat: 0,
					lng: 0
				},
				alt: null,
				scale: 1
			})
			imagePromises.push(dbPut(dbPromise, 'images', {
				frameId,
				image: file
			}))
		})
		const framesPromise = Promise.all(promises).then(async () => {
			if (meta != null) {
				const map = {}
				const indexByFile = {}
				meta.forEach((entry, i) => {
					map[entry.foto_1] = entry
					indexByFile[entry.foto_1] = i
				})
				frames
					.sort((a, b) => indexByFile[a.imageName] - indexByFile[b.imageName])
					.forEach((frame) => {
						const frameMeta = map[frame.imageName]
						if (frameMeta != null) {
							frame.latitude = frame.location.lat = parseFloat(frameMeta.lat_value1_gps)
							frame.longitude = frame.location.lng = parseFloat(frameMeta.lon_value1_gps)
							frame.azimuth = frameMeta.AUAT0_yaw / Math.PI * 180
						}
					})
			}
			await dbPut(dbPromise, 'frames', {
				flightId,
				frames
			})
		})
		Promise.all([framesPromise, ...imagePromises]).then(() => {
			router.navigate(flightFrames(flightPath))
			setParsing(false)
		}, (error) => {
			console.log(error)
			setParsing(false)
		})
	}, [files])

	useEffect(() => {
		if (error == null) {
			return
		}
		console.log(error)
		reset()
	}, [error])

	return <>
		<DbContext.Provider value={dbPromise}>
			{children}
		</DbContext.Provider>
		<Spinner
			className="absolute-spinner"
			hidden={state !== 'reading' && !parsing}
		>
			<div>
				Обробляємо файли
			</div>
		</Spinner>
	</>
}

export default DataHandler
