export const mapApiKey = 'AIzaSyDpjCAu53wd0lLzsJXOZVFEY_5p15N_Lm8'
export const mapId = '94e1a9e691ae8970'
export const lat = 49.86966374730945
export const lng = 31.190702910608213

export const mapConfig = {
	mapId,
	mapTypeId: 'hybrid',
	center: {
		lat,
		lng
	},
	zoom: 6,
	tilt: 0,
	streetViewControl: false,
	draggableCursor: 'default',
	clickableIcons: false
}

export const host = ''

export const dbName = 'kreyda'
