import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { flightFrame, flightFrameTarget } from '../../../../routing'
import { MarkerColors, TargetSideNames } from './util'

const TargetRow = ({
	target,
	flightId,
	frameId,
	targetId
}) => {
	const style = useMemo(() => ({
		color: MarkerColors[target.sideId] ?? MarkerColors[0]
	}), [target])

	return <NavLink
		to={targetId === target.id
			? flightFrame(flightId, frameId)
			: flightFrameTarget(flightId, frameId, target.id)}
		className="table-row d-flex align-items-center px-2 py-1"
	>
		<div className="flex-size-3">
			{target.title}
		</div>
		<div
			className="flex-size-1"
			style={style}
		>
			{TargetSideNames[target.sideId]}
		</div>
		<div className="flex-size-1">
			{target.typeId}
		</div>
		<div className="flex-size-2">
			{target.location.coordinates[1]} {target.location.coordinates[0]}
		</div>
	</NavLink>
}

export default TargetRow
