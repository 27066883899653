import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLocalState, useResetCounter } from '../common/util'
import { useZones } from '../data/dataAccessors'
import FlightsMap from './FlightsMap'
import FlightsTable from './FlightsTable'
import ZonesTable from './zones/ZonesTable'

const Flights = () => {
	const [counter, resetZones] = useResetCounter()
	const { flightId } = useParams()
	const { result: zones } = useZones([counter])
	const [hoveredZone, setHoveredZone] = useState(null)
	const [coords, setCoords] = useLocalState('coords', [])
	const [selectedZone, setSelectedZone] = useLocalState('zone')

	return <div className="flex-grow-1 d-flex">
		<div className="flex-size-1 d-flex flex-column">
			<ZonesTable
				className="flex-size-1"
				zones={zones}
				selectedZone={selectedZone}
				onSelectionChange={setSelectedZone}
				hoveredZone={hoveredZone}
				onHoverChange={setHoveredZone}
			/>
			<FlightsTable
				className="flex-size-2"
				coords={coords}
			/>
		</div>
		<FlightsMap
			className="flex-size-3"
			flightId={flightId}
			zones={zones}
			resetZones={resetZones}
			selectedZone={selectedZone}
			setSelectedZone={setSelectedZone}
			hoveredZone={hoveredZone}
			coords={coords}
			setCoords={setCoords}
		/>
	</div>
}

export default Flights
