import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState } from 'react'
import MarkerModal from '../../../../common/draggable/MarkerModal'
import Spinner from '../../../../common/spinner/Spinner'
import { useTargetsCreate } from '../../../../data/dataAccessors'
import { MarkerColors, TargetSideNames } from './util'

const types = [{
	id: 0,
	shape: 'square',
	name: 'Техніка'
}, {
	id: 1,
	shape: 'circle',
	name: 'Піхота'
}, {
	id: 2,
	shape: 'triangle',
	name: 'Споруда'
}]

const TargetModal = ({
	frameId,
	x,
	y,
	close,
	onSave
}) => {
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [typeId, setTypeId] = useState(0)
	const [sideId, setSideId] = useState(0)
	const {
		post,
		state
	} = useTargetsCreate()

	const save = useCallback((event) => {
		event.preventDefault()

		post({
			frame_id: frameId,
			uuid: crypto.randomUUID(),
			title,
			description,
			position_x: x,
			position_y: y,
			location: {
				lat: 0,
				lon: 0
			},
			target_type_id: typeId,
			target_side_id: sideId
		})
	}, [frameId, title, description, x, y, typeId, sideId])

	useEffect(() => {
		if (state === 'done') {
			onSave()
		}
	}, [state])

	return <MarkerModal className="px-3 pb-3 position-relative">
		<div className="py-2 d-flex align-items-center">
			<h1 className="modal-title fs-5">
				Додати ціль
			</h1>
			<div
				className="icon-button ms-auto"
				title="Закрити"
				onClick={close}
			>
				<FontAwesomeIcon icon={faClose}/>
			</div>
		</div>
		<form onSubmit={save}>
			<select
				className="form-control mb-2"
				onChange={(event) => setTypeId(event.target.value)}
			>
				{types.map(({
					id,
					name
				}) =>
					<option
						key={id}
						value={id}
					>
						{name}
					</option>)}
			</select>
			<select
				className="form-control mb-2"
				onChange={(event) => setSideId(parseInt(event.target.value) || 0)}
			>
				{TargetSideNames.map((name, id) =>
					<option
						key={id}
						value={id}
						color={MarkerColors[id]}
					>
						{name}
					</option>)}
			</select>
			<input
				className="form-control mb-2"
				placeholder="Назва"
				type="text"
				onChange={(event) => setTitle(event.target.value)}
			/>
			<textarea
				className="form-control mb-2"
				placeholder="Опис"
				onChange={(event) => setDescription(event.target.value)}
				rows="2"
			/>
			<div className="d-flex align-items-center flex-nowrap">
				<button
					className="btn btn-secondary"
					data-bs-dismiss="modal"
					onClick={close}
				>
					Скасувати
				</button>
				<button
					className="btn btn-primary ms-2"
					type="submit"
				>
					Додати
				</button>
			</div>
		</form>
		{state === 'progress' || state === 'done'
			? <Spinner
				className="absolute-spinner"
			/>
			: null}
	</MarkerModal>
}

export default TargetModal
