import { useContext, useEffect, useState } from 'react'
import { host } from '../config'
import ApiContext from '../login/ApiContext'

const headers = new Headers()
headers.append('Content-Type', 'application/json')

// export const get = (url) => fetch(`${host}/api/${url}`, {
// 	method: 'GET'
// })

export const post = (url, body) => fetch(`${host}/api/${url}`, {
	method: 'POST',
	headers,
	body: JSON.stringify(body)
})

// export const put = (url, body) => fetch(`${host}/api/${url}`, {
// 	method: 'PUT',
// 	headers,
// 	body: JSON.stringify(body)
// })

const passThrough = (value) => value

export const useApi = (prepare, deps) => {
	const handleResponseStatus = useContext(ApiContext)
	const [state, setState] = useState('pending') // 'progress', 'done', 'error'
	const [result, setResult] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		setResult(null)
		setError(null)
		let cancelled = false

		const params = prepare()

		if (params == null) {
			setState('pending')
			return
		}

		setState('progress')

		const {
			url,
			method = 'GET',
			body,
			mapResponse = passThrough
		} = params

		const options = method === 'GET'
			? { method }
			: {
				method,
				headers,
				body: JSON.stringify(body)
			}

		const handleError = (error) => {
			if (cancelled) {
				return
			}

			setError(error)
			setState('error')
		}

		fetch(`${host}/api/${url}`, options)
			.then(async (response) => {
				if (cancelled) {
					return
				}

				const error = await handleResponseStatus(response)

				if (error != null) {
					handleError(error)
					return
				}

				const result = await response.json()

				if (cancelled) {
					return
				}

				setResult(mapResponse(result))
				setState('done')
			})
			.catch(handleError)

		return () => {
			cancelled = true
		}
	}, deps)

	return {
		state,
		result,
		error
	}
}

export const createPostHook = (transform) => () => {
	const [body, setBody] = useState(null)

	const {
		state,
		result,
		error
	} = useApi(() => {
		return body == null
			? null
			: {
				method: 'POST',
				...transform(body)
			}
	}, [body])

	return {
		post: setBody,
		state,
		result,
		error
	}
}
