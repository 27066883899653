import { useCallback, useMemo, useState } from 'react'

export const useResetCounter = () => {
	const [counter, setCounter] = useState(0)
	const reset = useCallback(() => {
		setCounter(counter + 1)
	}, [counter])
	return [counter, reset]
}

export const useLocalState = (name, defaultValue = null) => {
	const initial = useMemo(() => {
		const value = localStorage.getItem(name)
		if (value == null) {
			return defaultValue
		}
		try {
			return JSON.parse(value)
		} catch (error) {
			console.log(`failed to parse localStorage value for "${name}"`)
			return defaultValue
		}
	}, [])
	const [value, setValue] = useState(initial ?? defaultValue)
	const set = useCallback((value) => {
		setValue(value)
		localStorage.setItem(name, JSON.stringify(value))
	}, [name])
	return [value, set]
}
