import classNames from 'classnames'
import svg from './spinner.svg'
import './style.css'

const Spinner = ({
	className = 'flex-grow-1',
	hidden = false,
	children
}) =>
	<div
		className={classNames(className, { hidden }, 'spinner-overlay d-flex flex-column align-items-center justify-content-center text-center text-white')}
	>
		<img src={svg}/>
		{children}
	</div>

export default Spinner
