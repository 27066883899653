import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './common/style.css'
import './common/table/style.css'

const root = createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>
)
