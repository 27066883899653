import { createSimpleStore } from './indexedDB'

export default [
	(db) => {
		createSimpleStore(db, 'frames', 'flightId')
		createSimpleStore(db, 'targets', 'frameId')
		createSimpleStore(db, 'images', 'frameId')
	},
	(db) => {
		createSimpleStore(db, 'zones', 'id')
	}
]
