import { useCallback, useEffect, useState } from 'react'

export const useDropZone = () => {
	const [state, setState] = useState('pending') // 'progress', 'done', 'error'
	const [files, setFiles] = useState(null)
	const [firstName, setFirstName] = useState(null)
	const [error, setError] = useState(null)

	const reset = useCallback(() => {
		setState('pending')
		setFiles(null)
		setFirstName(null)
		setError(null)
	}, [])

	useEffect(() => {
		const enter = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		const over = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		const drop = (event) => {
			event.preventDefault()
			event.stopPropagation()
			setState('progress')
			let firstNamePending = true
			const result = []
			const promises = []
			const handleFile = (file) => {
				result.push(file)
			}
			const handleEntry = async (handle) => {
				const promises = []
				if (handle.kind === 'file') {
					promises.push(handle.getFile().then(handleFile))
				} else if (handle.kind === 'directory') {
					for await (const value of handle.values()) {
						promises.push(handleEntry(value))
					}
				}
				await Promise.all(promises)
			}
			for (const item of event.dataTransfer.items) {
				if (item.kind !== 'file') {
					continue
				}
				if (firstNamePending) {
					setFirstName(item.getAsFile().name)
					firstNamePending = false
				}
				if (item.getAsFileSystemHandle == null) {
					result.push(item.getAsFile())
					continue
				}
				promises.push(item.getAsFileSystemHandle().then(handleEntry))
			}
			Promise.all(promises).then(() => {
				setFiles(result)
				setState('done')
			}, (error) => {
				console.log(error)
				setError(error)
				setState('error')
			})
		}
		document.addEventListener('dragenter', enter)
		document.addEventListener('dragover', over)
		document.addEventListener('drop', drop)
		return () => {
			document.removeEventListener('dragenter', enter)
			document.removeEventListener('dragover', over)
			document.removeEventListener('drop', drop)
		}
	}, [])

	return {
		state,
		files,
		firstName,
		error,
		reset
	}
}
