import { useCallback, useRef, useState } from 'react'

const defaultOffset = {
	x: 0,
	y: 0
}

export const useDraggableImageHandlers = (clickHandler, moveHandler, upHandler) => {
	const frameRef = useRef()
	const [zoom, setZoom] = useState(1)
	const [offset, setOffset] = useState(defaultOffset)
	const [dragPosition, setDragPosition] = useState(null)
	const [dragCountdown, setDragCountdown] = useState(0)

	const onClick = useCallback((event) => {
		if (clickHandler == null || dragCountdown === 0) {
			return
		}

		const rect = event.currentTarget.getBoundingClientRect()

		clickHandler({
			x: (event.clientX - rect.left) / zoom,
			y: (event.clientY - rect.top) / zoom
		})
	}, [dragCountdown, zoom, clickHandler])

	const onMouseDown = useCallback((event) => {
		setDragCountdown(3)
		setDragPosition({
			x: event.clientX,
			y: event.clientY
		})
	}, [])

	const onMouseUp = useCallback(() => {
		if (upHandler != null) {
			upHandler()
		}
		setDragPosition(null)
	}, [])

	const onMouseMove = useCallback((event) => {
		if (dragCountdown > 0) {
			setDragCountdown(dragCountdown - 1)
		}

		if (moveHandler != null && frameRef.current != null) {
			const rect = frameRef.current.getBoundingClientRect()
			const position = {
				x: (event.clientX - rect.left) / zoom,
				y: (event.clientY - rect.top) / zoom
			}

			if (moveHandler(event, position)) {
				return
			}
		}

		if (dragPosition != null) {
			if (event.buttons === 0) {
				setDragPosition(null)
				return
			}

			setOffset({
				x: offset.x + (event.clientX - dragPosition.x) / zoom,
				y: offset.y + (event.clientY - dragPosition.y) / zoom
			})

			setDragPosition({
				x: event.clientX,
				y: event.clientY
			})
		}
	}, [dragPosition, moveHandler, dragCountdown, zoom, offset])

	const onWheelCapture = useCallback((event) => {
		setZoom(Math.exp(Math.log(zoom) - (event.deltaX + event.deltaY + event.deltaZ) / 1000))
	}, [zoom])

	return {
		zoom,
		setZoom,
		offset,
		setOffset,
		dragPosition,
		frameRef,
		onClick,
		onMouseDown,
		onMouseUp,
		onMouseMove,
		onWheelCapture
	}
}
