import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState } from 'react'
import Spinner from '../../common/spinner/Spinner'
import { useZonesCreate } from '../../data/dataAccessors'

const ZoneModal = ({
	coords,
	close,
	onSave
}) => {
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const {
		post,
		state
	} = useZonesCreate()

	const save = useCallback((event) => {
		event.preventDefault()

		post({
			name,
			description,
			coords
		})
	}, [name, description, coords])

	useEffect(() => {
		if (state === 'done') {
			onSave()
		}
	}, [state])

	return <div className="image-marker-content rounded px-3 pb-3 position-relative">
		<div className="py-2 d-flex align-items-center">
			<h1 className="modal-title fs-5">
				Зберегти зону
			</h1>
			<div
				className="icon-button ms-auto"
				title="Закрити"
				onClick={close}
			>
				<FontAwesomeIcon icon={faClose}/>
			</div>
		</div>
		<form onSubmit={save}>
			<input
				className="form-control mb-2"
				placeholder="Назва"
				type="text"
				onChange={(event) => setName(event.target.value)}
			/>
			<textarea
				className="form-control mb-2"
				placeholder="Опис"
				onChange={(event) => setDescription(event.target.value)}
				rows="2"
			/>
			<div className="d-flex align-items-center flex-nowrap">
				<button
					className="btn btn-secondary"
					data-bs-dismiss="modal"
					onClick={close}
				>
					Скасувати
				</button>
				<button
					className="btn btn-primary ms-2"
					type="submit"
				>
					Зберегти
				</button>
			</div>
		</form>
		{state === 'progress' || state === 'done'
			? <Spinner
				className="absolute-spinner"
			/>
			: null}
	</div>
}

export default ZoneModal
