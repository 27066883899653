import { faCheck, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import Spinner from '../../../common/spinner/Spinner'
import { flightFrame, flightFrameApprove } from '../../../routing'
import './style.css'

const FramesTable = ({
	className,
	flightId,
	frames
}) => {
	return <div className={classNames('d-flex', className)}>
		{frames == null
			? <Spinner/>
			: <div className="flex-grow-1 overflow-auto">
				{frames.map(({
					id,
					imageName,
					approved
				}) => {
					return <div
						key={id}
						className="table-row d-flex align-items-center"
					>
						<NavLink
							to={flightFrame(flightId, id)}
							className="flex-grow-1 px-2 py-1"
						>
							{imageName}
						</NavLink>
						<NavLink
							className="icon-button"
							title={approved ? 'Прив\'язано. Натисніть щоб змінити' : 'Не прив\'язано. Натисніть щоб прив\'язати'}
							to={flightFrameApprove(flightId, id)}
						>
							<FontAwesomeIcon
								className={approved ? 'approved-color' : 'pending-color'}
								icon={approved ? faCheck : faQuestion}
							/>
						</NavLink>
					</div>
				})}
			</div>}
	</div>
}

export default FramesTable
