export const MarkerColors = [
	'silver',
	'limegreen',
	'deepskyblue',
	'gold',
	'red'
]

export const TargetSideNames = [
	'Не вказано',
	'Свій',
	'Нейтральний',
	'Невизначений',
	'Ворожий'
]
