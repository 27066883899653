import { useEffect, useMemo } from 'react'
import { createMarkerIcon, useMarkerPath, usePolyline } from '../common/map/util'
import { useGoogleApi } from '../common/mapLoader'

export const useFramesPath = (map, frames, onClick) => {
	const coreApi = useGoogleApi('core')

	const [path, alt] = useMemo(() => frames == null || coreApi == null
		? [null, null]
		: [
			frames.map(({ location }) =>
				new coreApi.LatLng(location.lat, location.lng)),
			frames.map(({ alt }) => alt == null
				? null
				: new coreApi.LatLng(alt.lat, alt.lng))
		], [frames])

	usePolyline(map, path)
	useMarkerPath(map, path, 'red', onClick)
	useMarkerPath(map, alt, 'blue', onClick)
}

export const useZonePolygon = (map, coords, color, handle = null, draggable = true, cursor) => {
	const coreApi = useGoogleApi('core')
	const mapsApi = useGoogleApi('maps')
	const markerApi = useGoogleApi('marker')

	useEffect(() => {
		if (coords == null || coreApi == null || mapsApi == null || markerApi == null || map == null) {
			return
		}

		const markers = coords.map((position) => new markerApi.Marker({
			position,
			icon: createMarkerIcon(coreApi, color),
			draggable,
			cursor,
			zIndex: 2,
			map
		}))

		const polygon = coords.length < 3
			? null
			: new mapsApi.Polygon({
				paths: coords,
				draggable: false,
				editable: false,
				cursor,
				strokeColor: color,
				fillColor: color,
				strokeOpacity: 0.7,
				strokeWeight: 1,
				fillOpacity: 0.3,
				zIndex: 1,
				map
			})

		if (handle != null) {
			handle([markers, polygon])
		}

		return () => {
			markers.forEach((marker) => {
				marker.setMap(null)
			})
			if (polygon != null) {
				polygon.setMap(null)
			}
		}
	}, [coords, coreApi, mapsApi, markerApi, map])
}
