import { useCallback, useEffect, useState } from 'react'
import Spinner from '../common/spinner/Spinner'
import { post } from '../data/api'
import ApiContext from './ApiContext'
import Login from './Login'

const LoginHandler = ({ children }) => {
	const [loggedIn, setLoggedIn] = useState(true)

	const handleError = useCallback((error) => {
		console.log('api request failed', error)
	}, [])

	const handleResponseStatus = useCallback(({
		ok,
		status
	}) => {
		if (ok) {
			return
		}
		// TODO: read body?
		if (status === 401 || status === 403) {
			setLoggedIn(false)
		}
		throw new Error(`status ${status}`)
	}, [])

	const handleLogin = useCallback(({
		login,
		password
	}) => {
		setLoggedIn(null)
		post('auth/login', {
			login,
			password
		})
			.then(() => {
				const check = () => {
					post('auth/check_login', {})
						.then(async (response) => {
							await handleResponseStatus(response)
							const result = await response.json()
							const role = result.role_id
							if (role == null) {
								setTimeout(check, 1000)
							} else {
								// console.log('user', role)
								setLoggedIn(true)
							}
						})
						.catch(handleError)
				}
				check()
			})
			.catch(handleError)
	}, [])

	useEffect(() => {
		post('auth/check_user', {})
			.then(async (response) => {
				await handleResponseStatus(response)
				// console.log('user', response.role_id)
				setLoggedIn(true)
			})
			.catch(handleError)
	}, [])

	return loggedIn == null
		? <Spinner
			className="absolute-spinner"
		/>
		: loggedIn
			? <ApiContext.Provider value={handleResponseStatus}>
				{children}
			</ApiContext.Provider>
			: <Login onSubmit={handleLogin}/>
}

export default LoginHandler
