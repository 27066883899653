import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import Spinner from '../common/spinner/Spinner'
import { useFlights } from '../data/dataAccessors'
import { flight, flightFrames } from '../routing'

const FlightsTable = ({
	coords,
	className
}) => {
	const { result: flights } = useFlights(coords)

	return <div className={classNames('d-flex', className)}>
		{coords.length <= 2
			? <div className="flex-grow-1 d-flex p-3 align-items-center justify-content-center text-center text-white">
				Оберіть зону на мапі щоб фільтрувати вильоти
			</div>
			: flights == null
				? <Spinner/>
				: flights.length === 0
					? <div className="flex-grow-1 d-flex p-3 align-items-center justify-content-center text-center text-white">
						Нічого не знайдено. Спробуйте розширити зону пошуку
					</div>
					: <div className="flex-grow-1 overflow-auto">
						{flights.map(({
							id,
							name
						}) =>
							<div
								key={id}
								className="table-row d-flex align-items-center"
							>
								<NavLink
									className="flex-grow-1 px-2 py-1"
									to={flight(id)}
								>
									{name}
								</NavLink>
								<NavLink
									className="icon-button"
									to={flightFrames(id)}
								>
									<FontAwesomeIcon icon={faEye}/>
								</NavLink>
							</div>)}
					</div>}
	</div>
}

export default FlightsTable
