export const parseCsvRow = (string) => {
	const { length } = string
	let i = 0
	let from = 0
	const result = []
	let inValue = false

	while (i < length) {
		const char = string[i]
		if (inValue) {
			if (char === '"') {
				if (i + 1 === length) {
					result.push(string.substring(from, i))
					return result
				} else if (string[i + 1] === '"') {
					i++
				} else if (string[i + 1] === ',') {
					result.push(string.substring(from, i))
					i++
					from = i + 1
					inValue = false
				} else {
					throw new Error()
				}
			}
		} else {
			if (char === ',') {
				result.push(string.substring(from, i))
				from = i + 1
			} else if (char === '"') {
				if (from !== i) {
					throw new Error()
				}
				from++
				inValue = true
			}
		}
		i++
	}

	result.push(string.substring(from))
	return result
}

export const readCsv = (file) => new Promise((resolve, reject) => {
	const reader = new FileReader()
	reader.addEventListener('load', () => {
		try {
			resolve(reader.result.trim().split(/\s*\n\s*/).map(parseCsvRow))
		} catch (error) {
			reject(error)
		}
	})
	reader.addEventListener('error', (error) => {
		reject(error)
	})
	reader.readAsText(file)
})

export const mapCsv = (rows) => {
	const { length } = rows
	const header = rows[0]
	const result = new Array(length - 1)
	for (let i = 1; i < length; i++) {
		const row = rows[i]
		const entry = {}
		header.forEach((name, i) => {
			entry[name] = row[i]
		})
		result[i - 1] = entry
	}
	return result
}
