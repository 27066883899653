import classNames from 'classnames'
import Spinner from '../../../../common/spinner/Spinner'
import TargetRow from './TargetRow'

const TargetsTable = ({
	className,
	targets,
	flightId,
	frameId,
	targetId
}) =>
	<div className={classNames('d-flex', className)}>
		{targets == null
			? <Spinner/>
			: <div className="flex-grow-1 overflow-auto">
				{targets.map((target) =>
					<TargetRow
						key={target.id}
						target={target}
						flightId={flightId}
						frameId={frameId}
						targetId={targetId}
					/>)}
			</div>}
	</div>

export default TargetsTable
