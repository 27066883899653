import { useCallback, useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { useNavigate } from 'react-router-dom'
import { useMap } from '../../../common/map/util'
import { useGoogleApi } from '../../../common/mapLoader'
import { flightFrame } from '../../../routing'
import { useFramesPath } from '../../util'
import svg from './compass.svg'
import './style.css'

const FlightMap = ({
	className,
	frames,
	frame
}) => {
	const ref = useRef()
	const navigate = useNavigate()
	const coreApi = useGoogleApi('core')

	const map = useMap(ref)

	const azimuthContainer = useMemo(() => {
		if (map == null || coreApi == null) {
			return
		}

		const div = document.createElement('div')
		map.controls[coreApi.ControlPosition.LEFT_BOTTOM].push(div)
		return ReactDOM.createRoot(div)
	}, [map, coreApi])

	useEffect(() => {
		if (azimuthContainer == null) {
			return
		}

		if (frame == null) {
			azimuthContainer.render(<></>)
			return
		}

		azimuthContainer.render(
			<div className="azimuth-widget d-flex align-items-center justify-content-center position-relative">
				<img
					src={svg}
					className="azimuth-arrow"
					style={{ rotate: `${frame.azimuth}deg` }}
				/>
				<div className="azimuth-label p-1">
					{Math.round(frame.azimuth)}&#xb0;
				</div>
			</div>
		)
	}, [azimuthContainer, frame])

	const onClick = useCallback((i) => {
		if (frames == null || frames[i] == null) {
			return
		}
		navigate(flightFrame(frames[i].flightId, frames[i].id))
	}, [frames])

	useFramesPath(map, frames, onClick)

	useEffect(() => {
		if (coreApi == null || map == null || frame == null) {
			return
		}

		// this.azimuth.setAzimuth(this.storage.frameActive.azimuth)
		map.moveCamera({
			center: new coreApi.LatLng(
				frame.location.lat,
				frame.location.lng
			),
			zoom: 18,
			heading: frame.azimuth
		})
	}, [coreApi, map, frame])

	return <div
		className={className}
		ref={ref}
	/>
}

export default FlightMap
