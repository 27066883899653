import { useCallback, useContext, useEffect, useState } from 'react'
import DbContext from './DbContext'

export const createSimpleStore = (db, name, keyPath) => {
	const objectStore = db.createObjectStore(name, {
		keyPath
	})
	objectStore.createIndex(keyPath, keyPath, { unique: true })
}

export const initDb = (dbName, versions) => new Promise((resolve, reject) => {
	const { length } = versions
	const request = indexedDB.open(dbName, length)
	request.onsuccess = ({ target: { result } }) => {
		resolve(result)
	}
	request.onerror = ({ target: { error } }) => {
		console.log(error)
		reject(error)
	}
	request.onupgradeneeded = (event) => {
		const {
			oldVersion,
			target: { result: db }
		} = event
		for (let i = oldVersion == null ? 0 : oldVersion; i < length; i++) {
			versions[i](db)
		}
	}
})

export const useDb = () => {
	const dbPromise = useContext(DbContext)
	const [db, setDb] = useState(null)

	useEffect(() => {
		dbPromise.then((db) => {
			setDb(db)
		})
	}, [])

	return db
}

export const useDbOperation = (execute, deps = []) => {
	const db = useDb()
	const [state, setState] = useState('pending') // 'progress', 'done', 'error'
	const [result, setResult] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		if (db == null) {
			return
		}
		const operation = execute(db)
		if (operation == null) {
			return
		}
		let cancelled = false
		setState('progress')
		operation.onsuccess = (event) => {
			if (cancelled) {
				return
			}
			setResult(event.target.result)
			setState('done')
		}
		operation.onerror = (event) => {
			if (cancelled) {
				return
			}
			setError(event.target.error)
			setState('error')
		}
		return () => {
			// TODO: abort transaction?
			cancelled = true
		}
	}, [db, ...deps])

	return {
		state,
		result,
		error
	}
}

export const useDbEntry = (storeName, id) => useDbOperation((db) => {
	return storeName == null || id == null
		? null
		: db.transaction(storeName, 'readonly').objectStore(storeName).get(id)
}, [storeName, id])

export const dbWrite = async (dbPromise, storeName, callback) => {
	const db = await dbPromise
	return await new Promise((resolve, reject) => {
		const transaction = db.transaction(storeName, 'readwrite')
		callback(transaction)
		transaction.oncomplete = (event) => {
			resolve(event.target.result)
		}
		transaction.onerror = (event) => {
			reject(event.target.error)
		}
	})
}

export const dbPut = async (dbPromise, storeName, entry, id) =>
	dbWrite(dbPromise, storeName, (transaction) => {
		transaction.objectStore(storeName).put(entry, id)
	})

export const useDbPut = (storeName) => {
	const dbPromise = useContext(DbContext)
	const [state, setState] = useState('pending') // 'progress', 'done', 'error'
	const [error, setError] = useState(null)

	const put = useCallback(async (entry, id) => {
		if (dbPromise == null || storeName == null) {
			throw new Error('db context not set up')
		}
		setState('progress')
		try {
			const result = await dbPut(dbPromise, storeName, entry, id)
			setState('done')
			return result
		} catch (error) {
			setError(error)
			setState('error')
			throw error
		}
	}, [dbPromise, storeName])

	return {
		put,
		state,
		error
	}
}
