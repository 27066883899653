import classNames from 'classnames'

const MarkerModal = ({
	className,
	children
}) =>
	<div className="image-marker-modal">
		<div className="image-marker-arrow"/>
		<div className={classNames(className, 'image-marker-content rounded')}>
			{children}
		</div>
	</div>

export default MarkerModal
