import {
	faChevronLeft,
	faChevronRight,
	faCompass,
	faCrop,
	faCrosshairs,
	faExpand,
	faFont,
	faSearchMinus,
	faSearchPlus,
	faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import DraggableImage from '../../../common/draggable/DraggableImage'
import ImageArea from '../../../common/draggable/ImageArea'
import ImageMarker from '../../../common/draggable/ImageMarker'
import { useDraggableImageHandlers } from '../../../common/draggable/util'
import { useGoogleApi } from '../../../common/mapLoader'
import Spinner from '../../../common/spinner/Spinner'
import { useTargetDetection } from '../../../data/dataAccessors'
import { flightFrame, flightFrameApprove } from '../../../routing'
import './style.css'
import TargetModal from './targets/TargetModal'
import { MarkerColors } from './targets/util'

const Frame = ({
	className,
	flightId,
	frame,
	frames,
	target,
	targets,
	image,
	resetTargets
}) => {
	const geometryApi = useGoogleApi('geometry')
	const [targetEditorEnabled, setTargetEditorEnabled] = useState(false)
	const [autoDetectionEnabled, setAutoDetectionEnabled] = useState(false)
	const [draggedPoint, setDraggedPoint] = useState(null)
	const [draggedPointPosition, setDraggedPointPosition] = useState(null)
	const [pendingTarget, setPendingTarget] = useState(null)
	const [imageWithFallback, setImageWithFallback] = useState(image)

	const {
		result: detectedTargets,
		state: targetsState
	} = useTargetDetection(autoDetectionEnabled ? frame : null)

	const areas = useMemo(() => {
		if (!autoDetectionEnabled || targetsState === 'progress' || detectedTargets == null) {
			return null
		}
		return detectedTargets.map((target, i) =>
			<ImageArea
				key={i}
				area={{
					x: target.bbox.x,
					y: target.bbox.y,
					width: target.bbox.w,
					height: target.bbox.h
				}}
				color={`rgb(${255 - target.score * 150},${105 + target.score * 150},20)`}
				tooltip={`${(target.score * 100).toFixed(0)}% ${target.class_name}`}
			/>)
	}, [detectedTargets, autoDetectionEnabled, targetsState])

	const detectedTargetCount = useMemo(() => areas == null ? 0 : Math.min(areas.length, 9), [areas])

	useEffect(() => {
		if (image != null) {
			setImageWithFallback(image)
		}
	}, [image])

	const closeModal = useCallback(() => {
		setPendingTarget(null)
	}, [])

	const closeModalAndResetTargets = useCallback(() => {
		closeModal()
		resetTargets()
	}, [resetTargets])

	const frameIndex = useMemo(() => {
		if (frame == null || frames == null) {
			return null
		}

		const index = frames.findIndex(({ id }) => id === frame.id)

		return index === -1
			? null
			: index
	}, [frame, frames])

	const nextId = useMemo(() => {
		if (frames == null || frameIndex == null) {
			return null
		}

		const nextIndex = frameIndex + 1

		if (nextIndex >= frames.length) {
			return null
		}

		return frames[nextIndex].id
	}, [frames, frameIndex])

	const prevId = useMemo(() => {
		if (frames == null || frameIndex == null || frameIndex === 0) {
			return null
		}
		return frames[frameIndex - 1].id
	}, [frames, frameIndex])

	const onFrameClick = useCallback((position) => {
		if (!targetEditorEnabled || geometryApi == null || frame == null) {
			return
		}

		const {
			width,
			height,
			azimuth,
			scale
		} = frame

		const x = position.x - width / 2
		const y = position.y - height / 2
		const distance = Math.hypot(y, x)
		const angle = Math.atan2(y, x)

		const lct = geometryApi.spherical.computeOffset(
			frame.location,
			distance * scale,
			angle + azimuth
		)

		console.log(x, y, distance, angle, lct)

		setPendingTarget(position)
	}, [targetEditorEnabled, geometryApi, frame])

	const markers = useMemo(() => {
		if (targets == null) {
			return []
		}

		return targets.map(({
			id,
			x,
			y,
			sideId
		}) =>
			<ImageMarker
				key={id}
				point={draggedPoint === id && draggedPointPosition != null
					? draggedPointPosition
					: {
						x,
						y
					}}
				color={MarkerColors[sideId] ?? MarkerColors[0]}
				dragged={draggedPoint === id}
				onMouseDown={() => {
					setDraggedPoint(id)
					setDraggedPointPosition(null)
				}}
			/>)
	}, [targets, draggedPoint, draggedPointPosition])

	const onFrameMove = useCallback((event, position) => {
		if (draggedPoint == null) {
			return false
		}
		if (event.buttons === 0) {
			setDraggedPoint(null)
		} else {
			setDraggedPointPosition(position)
		}
		return true
	}, [draggedPoint])

	const onFrameUp = useCallback(() => {
		setDraggedPoint(null)
	}, [])

	const {
		zoom,
		setZoom,
		offset,
		setOffset,
		dragPosition,
		frameRef,
		onClick,
		onMouseDown,
		onMouseUp,
		onMouseMove,
		onWheelCapture
	} = useDraggableImageHandlers(onFrameClick, onFrameMove, onFrameUp)

	useEffect(() => {
		if (target != null && frame != null) {
			setOffset({
				x: frame.width / 2 - target.x,
				y: frame.height / 2 - target.y
			})
		}
	}, [target, frame])

	return <div
		className={classNames(className, 'd-flex overflow-hidden position-relative')}
		onMouseMove={onMouseMove}
		onMouseUp={onMouseUp}
	>
		{imageWithFallback == null || frame == null
			? null
			: <DraggableImage
				frameRef={frameRef}
				src={imageWithFallback}
				width={frame.width}
				height={frame.height}
				zoom={zoom}
				offset={offset}
				dragged={dragPosition != null}
				onClick={onClick}
				onMouseDown={onMouseDown}
				onWheelCapture={onWheelCapture}
			>
				{markers}
				{areas}
				{pendingTarget == null
					? null
					: <ImageMarker point={pendingTarget}>
						<TargetModal
							frameId={frame.id}
							x={pendingTarget.x}
							y={pendingTarget.y}
							close={closeModal}
							onSave={closeModalAndResetTargets}
						/>
					</ImageMarker>}
			</DraggableImage>}
		<div className="instrument-menu bg-body-tertiary">
			{/* <FontAwesomeIcon icon={faGripVertical}/> TODO: enable */}
			{frame == null
				? <div
					className="icon-button disabled"
					title="Прив'язати"
				>
					<FontAwesomeIcon icon={faExpand}/>
				</div>
				: <NavLink
					className="icon-button"
					title="Прив'язати"
					to={flightFrameApprove(flightId, frame.id)}
				>
					<FontAwesomeIcon icon={faExpand}/>
				</NavLink>}
			{frame != null && frame.persisted
				? <div
					className={classNames({ active: autoDetectionEnabled }, 'icon-button position-relative overflow-hidden')}
					title="Автоматичне визначення цілей"
					onClick={() => setAutoDetectionEnabled(!autoDetectionEnabled)}
				>
					<FontAwesomeIcon icon={faWandMagicSparkles}/>
					{autoDetectionEnabled && targetsState === 'done'
						? <div className="badge">
							{detectedTargetCount}
						</div>
						: null}
					<Spinner
						className="position-absolute scale-50"
						hidden={targetsState !== 'progress' || !autoDetectionEnabled}
					/>
				</div>
				: <div
					className="icon-button disabled"
					title="Автоматичне визначення цілей працює лише в онлайн режимі"
				>
					<FontAwesomeIcon icon={faWandMagicSparkles}/>
				</div>}
			<div
				className={classNames({ active: targetEditorEnabled }, 'icon-button')}
				title="Додати ціль"
				onClick={() => setTargetEditorEnabled(!targetEditorEnabled)}
			>
				<FontAwesomeIcon icon={faCrosshairs}/>
			</div>
			<div
				className="icon-button"
				title="???"
				onClick={() => alert('Text')}
			>
				<FontAwesomeIcon icon={faFont}/>
			</div>
			<div
				className="icon-button"
				title="???"
				onClick={() => alert('Note crop')}
			>
				<FontAwesomeIcon icon={faCrop}/>
			</div>
			<div
				className="icon-button"
				title="???"
				onClick={() => alert('Compass')}
			>
				<FontAwesomeIcon icon={faCompass}/>
			</div>
		</div>
		<div className="navigation-menu bg-body-tertiary">
			{/* <FontAwesomeIcon icon={faGripVertical}/> TODO: enable */}
			{prevId == null
				? <div
					className="icon-button disabled"
					title="Попередній фрейм"
				>
					<FontAwesomeIcon icon={faChevronLeft}/>
				</div>
				: <NavLink
					className="icon-button"
					title="Попередній фрейм"
					to={flightFrame(flightId, prevId)}
				>
					<FontAwesomeIcon icon={faChevronLeft}/>
				</NavLink>}
			{nextId == null
				? <div
					className="icon-button disabled"
					title="Наступний фрейм"
				>
					<FontAwesomeIcon icon={faChevronRight}/>
				</div>
				: <NavLink
					className="icon-button"
					title="Наступний фрейм"
					to={flightFrame(flightId, nextId)}
				>
					<FontAwesomeIcon icon={faChevronRight}/>
				</NavLink>}
			{/* <FontAwesomeIcon icon={faExpand}/> TODO: what is it supposed to do? */}
			<div
				className="icon-button"
				title="Збільшити"
				onClick={() => setZoom(zoom * 1.5)}
			>
				<FontAwesomeIcon icon={faSearchPlus}/>
			</div>
			<div
				className="icon-button"
				title="Зменшити"
				onClick={() => setZoom(zoom / 1.5)}
			>
				<FontAwesomeIcon icon={faSearchMinus}/>
			</div>
		</div>
		<Spinner
			className="absolute-spinner"
			hidden={frame != null && image != null}
		/>
	</div>
}

export default Frame
