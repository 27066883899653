import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { Navigate, NavLink, useParams } from 'react-router-dom'
import Spinner from '../../common/spinner/Spinner'
import { useResetCounter } from '../../common/util'
import { useFrames, useImage, useTargets } from '../../data/dataAccessors'
import { flightFrame, flights } from '../../routing'
import Approval from './frame/approval/Approval'
import Frame from './frame/Frame'
import TargetsTable from './frame/targets/TargetsTable'
import FramesTable from './frames/FramesTable'
import FlightMap from './map/FlightMap'

const Flight = () => {
	const {
		flightId,
		frameId,
		targetId,
		page
	} = useParams()

	const [counter, resetTargets] = useResetCounter()
	const frames = useFrames(flightId)
	const targets = useTargets(frameId, [frameId, counter])
	const image = useImage(frameId)

	const frame = useMemo(() => {
		if (frames == null || frameId == null) {
			return null
		}

		return frames.find(({ id }) => id === frameId) ?? null
	}, [frames, frameId])

	const target = useMemo(() => {
		if (targets == null || targetId == null || page !== 'targets') {
			return null
		}

		return targets.find(({ id }) => id === targetId) ?? null
	}, [targets, targetId, page])

	if (frameId == null) {
		if (frames == null) {
			return <Spinner/>
		}

		if (frames.length === 0) {
			// TODO: placeholder that flight has no frames
			return <Spinner/>
		}

		return <Navigate
			to={flightFrame(flightId, frames[0].id)}
			replace
		/>
	}

	if (page === 'approve') {
		return <Approval
			backLink={flightFrame(flightId, frameId)}
			frame={frame}
			image={image}
		/>
	}

	return <div className="flex-grow-1 d-flex overflow-hidden">
		<div className="flex-size-1 d-flex flex-column">
			<NavLink
				className="text-white px-2 py-1 d-flex align-items-center"
				to={flights()}
			>
				<FontAwesomeIcon icon={faArrowLeft}/>
				<span className="ms-2">Повернутись до списку</span>
			</NavLink>
			<FramesTable
				className="flex-size-3"
				flightId={flightId}
				frames={frames}
			/>
			<FlightMap
				className="flex-size-1"
				frames={frames}
				frame={frame}
			/>
		</div>
		<div className="flex-size-3 d-flex flex-column overflow-hidden">
			<Frame
				className="flex-size-3"
				flightId={flightId}
				frames={frames}
				frame={frame}
				target={target}
				targets={targets}
				image={image}
				resetTargets={resetTargets}
			/>
			<TargetsTable
				className="flex-size-1"
				targets={targets}
				flightId={flightId}
				frameId={frameId}
				targetId={targetId}
			/>
		</div>
	</div>
}

export default Flight
