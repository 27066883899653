import classNames from 'classnames'
import { useCallback } from 'react'

const ZonesTableRow = ({
	id,
	name,
	selectedZone,
	onSelectionChange,
	hoveredZone,
	onHoverChange
}) => {
	const onClick = useCallback(() => {
		onSelectionChange(id)
	}, [onSelectionChange])

	const onEnter = useCallback(() => {
		onHoverChange(id)
	}, [onHoverChange])

	const onLeave = useCallback(() => {
		if (hoveredZone === id) {
			onHoverChange(null)
		}
	}, [hoveredZone, onHoverChange])

	return <div
		className={classNames({ active: id === selectedZone }, 'table-row d-flex align-items-center px-2 py-1 cursor-pointer')}
		onClick={onClick}
		onMouseEnter={onEnter}
		onMouseLeave={onLeave}
	>
		{name}
	</div>
}

export default ZonesTableRow
