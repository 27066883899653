import classNames from 'classnames'
import { useCallback, useContext, useMemo } from 'react'
import './style.css'
import ZoomContext from './ZoomContext'

const ImageMarker = ({
	className,
	point,
	color,
	onMouseDown,
	dragged,
	children
}) => {
	const zoom = useContext(ZoomContext)

	const style = useMemo(() => ({
		'--color': color,
		left: `${point.x * zoom}px`,
		top: `${point.y * zoom}px`
	}), [point, zoom, color])

	const callback = useCallback((event) => {
		if (event.target.closest('.image-marker-modal')) {
			event.stopPropagation()
			return
		}

		if (onMouseDown == null) {
			return
		}

		event.preventDefault()
		event.stopPropagation()

		onMouseDown(event)
	}, [onMouseDown])

	return <div
		className={classNames('image-marker', { dragged }, className)}
		style={style}
		onMouseDown={callback}
	>
		<div className="image-marker-point"/>
		{children}
	</div>
}

export default ImageMarker
