import classNames from 'classnames'
import { useContext, useMemo } from 'react'
import './style.css'
import ZoomContext from './ZoomContext'

const ImageArea = ({
	className,
	area,
	color,
	tooltip,
	children
}) => {
	const zoom = useContext(ZoomContext)

	const style = useMemo(() => ({
		'--color': color,
		left: `${area.x * zoom}px`,
		top: `${area.y * zoom}px`,
		width: `${area.width * zoom}px`,
		height: `${area.height * zoom}px`
	}), [area, zoom, color])

	return <div
		className={classNames('image-area', className)}
		style={style}
		title={tooltip}
	>
		{children}
	</div>
}

export default ImageArea
