import { useState } from 'react'

const Login = ({ onSubmit }) => {
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')

	return <form
		className="d-flex flex-column align-items-center justify-content-center"
		onSubmit={(event) => {
			event.preventDefault()
			onSubmit({
				login,
				password
			})
		}}
	>
		<input
			className="form-control mb-2"
			placeholder="Логін"
			onChange={(event) => setLogin(event.target.value)}
		/>
		<input
			className="form-control mb-2"
			type="password"
			placeholder="Пароль"
			onChange={(event) => setPassword(event.target.value)}
		/>
		<button
			className="btn btn-primary"
			type="submit"
		>
			Увійти
		</button>
	</form>
}

export default Login
