import classNames from 'classnames'
import Spinner from '../../common/spinner/Spinner'
import ZonesTableRow from './ZonesTableRow'

const ZonesTable = ({
	className,
	zones,
	selectedZone,
	onSelectionChange,
	hoveredZone,
	onHoverChange
}) => {
	return <div className={classNames('d-flex', className)}>
		{zones == null
			? <Spinner/>
			: zones.length === 0
				? <div
					className="flex-grow-1 d-flex p-3 align-items-center justify-content-center text-center text-white"
				>
					Щоб зберегти зону оберіть декілька точок на карті та натисніть на виділену область
				</div>
				: <div className="flex-grow-1 overflow-auto">
					{zones.map(({
						id,
						name
					}) =>
						<ZonesTableRow
							key={id}
							id={id}
							name={name}
							selectedZone={selectedZone}
							onSelectionChange={onSelectionChange}
							hoveredZone={hoveredZone}
							onHoverChange={onHoverChange}
						/>)}
				</div>}
	</div>
}

export default ZonesTable
