import classNames from 'classnames'
import { useMemo } from 'react'
import './style.css'
import ZoomContext from './ZoomContext'

const DraggableImage = ({
	parentRef,
	frameRef,
	src,
	width,
	height,
	zoom,
	offset,
	dragged,
	onClick,
	onMouseDown,
	onWheelCapture,
	children
}) => {
	const frameStyle = useMemo(() => {
		return {
			width: `${width * zoom}px`,
			height: `${height * zoom}px`
		}
	}, [width, height, zoom])

	const offsetStyle = useMemo(() => ({
		left: `${offset.x * zoom}px`,
		top: `${offset.y * zoom}px`
	}), [offset, zoom])

	return <div
		ref={parentRef}
		className="flex-grow-1 d-flex align-items-center justify-content-center text-nowrap overflow-hidden position-relative"
		onMouseDown={onMouseDown}
		onWheelCapture={onWheelCapture}
	>
		<div className="image-center">
			<div
				ref={frameRef}
				className={classNames({ dragged }, 'image-container position-relative')}
				style={offsetStyle}
				onClick={onClick}
			>
				<img
					className="frame"
					src={src}
					style={frameStyle}
					draggable={false}
				/>
				<ZoomContext.Provider value={zoom}>
					{children}
				</ZoomContext.Provider>
			</div>
		</div>
	</div>
}

export default DraggableImage
