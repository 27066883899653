import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMap } from '../common/map/util'
import { useGoogleApi } from '../common/mapLoader'
import { useFrames } from '../data/dataAccessors'
import { useFramesPath, useZonePolygon } from './util'
import ZoneModal from './zones/ZoneModal'

const FlightsMap = ({
	className,
	flightId,
	coords,
	setCoords,
	zones,
	resetZones,
	selectedZone,
	setSelectedZone,
	hoveredZone
}) => {
	const ref = useRef()
	const coreApi = useGoogleApi('core')
	const geometryApi = useGoogleApi('geometry')
	const [[markers, polygon], setZone] = useState([])
	const [saveZoneModalVisible, setSaveZoneModalVisible] = useState(false)

	const map = useMap(ref)

	const addPoint = useCallback((input, add) => {
		if (geometryApi == null) {
			return
		}
		setSelectedZone(null)
		const { length } = input
		if (length <= 2) {
			setCoords([...input, add])
			return
		}
		let minRng = geometryApi.spherical.computeDistanceBetween(input[0], add) +
			geometryApi.spherical.computeDistanceBetween(input[length - 1], add) -
			geometryApi.spherical.computeDistanceBetween(input[0], input[length - 1])
		let index = 0
		for (let i = 1; i < length; i++) {
			const range = geometryApi.spherical.computeDistanceBetween(input[i - 1], add) +
				geometryApi.spherical.computeDistanceBetween(input[i], add) -
				geometryApi.spherical.computeDistanceBetween(input[i - 1], input[i])
			if (range < minRng) {
				minRng = range
				index = i
			}
		}
		const result = input.slice()
		result.splice(index, 0, add)
		setCoords(result)
	}, [geometryApi])

	useEffect(() => {
		if (selectedZone == null || zones == null) {
			return
		}
		const zone = zones.find(({ id }) => id === selectedZone)
		if (zone != null) {
			setCoords(zone.coords)
		}
	}, [zones, selectedZone])

	useEffect(() => {
		if (coreApi == null || map == null) {
			return
		}

		map.addListener('click', ({ latLng }) => {
			setSaveZoneModalVisible(false)
			addPoint(coords, latLng.toJSON())
		})

		map.addListener('rightclick', () => {
			setSelectedZone(null)
			setCoords([])
		})

		return () => {
			coreApi.event.clearInstanceListeners(map)
		}
	}, [coords, addPoint, coreApi, map, selectedZone])

	useEffect(() => {
		if (markers == null) {
			return
		}

		markers.forEach((marker, i) => {
			marker.addListener('dragend', () => {
				const result = coords.slice()
				result.splice(i, 1)
				addPoint(result, marker.position.toJSON())
			})

			marker.addListener('rightclick', () => {
				const result = coords.slice()
				result.splice(i, 1)
				setSelectedZone(null)
				setCoords(result)
			})
		})

		return () => {
			markers.forEach((marker) => {
				coreApi.event.clearInstanceListeners(marker)
			})
		}
	}, [markers, coords, addPoint])

	// const modal = useMemo(() => document.createElement('div'), [])

	useEffect(() => {
		if (polygon == null) {
			return
		}

		// let marker = null

		polygon.addListener('click', () => {
			setSaveZoneModalVisible(true)
			// marker = new markerApi.AdvancedMarkerElement({
			// 	map,
			// 	position: event.latLng,
			// 	content: modal,
			// 	zIndex: 5,
			// 	gmpClickable: true
			// })
		})

		// return () => {
		// 	if (marker != null) {
		// 		marker.setMap(null)
		// 	}
		// }
	}, [polygon])

	const greyZone = useMemo(() => {
		if (hoveredZone == null || zones == null || selectedZone === hoveredZone) {
			return null
		}
		const zone = zones.find(({ id }) => id === hoveredZone)
		return zone == null
			? null
			: zone.coords
	}, [zones, hoveredZone, selectedZone])

	useZonePolygon(map, greyZone, 'lightgrey', null, false, 'default')
	useZonePolygon(map, coords, 'limegreen', setZone)

	const frames = useFrames(flightId)

	useFramesPath(map, frames)

	// TODO: this part doesn't work for whatever reason
	// useEffect(() => {
	// 	if (path == null || coreApi == null || map == null) {
	// 		return
	// 	}
	//
	// 	const { length } = path
	//
	// 	if (length > 0) {
	// 		const bounds = new coreApi.LatLngBounds()
	// 		for (let i = 0; i < length; i++) {
	// 			bounds.extend(path[i])
	// 		}
	// 		map.fitBounds(bounds, 50)
	// 	}
	// }, [path, coreApi, map])

	const closeModal = useCallback(() => {
		setSaveZoneModalVisible(false)
	}, [])

	const closeModalAndResetZones = useCallback(() => {
		closeModal()
		resetZones()
	}, [resetZones])

	return <div
		className={className}
		ref={ref}
	>
		{saveZoneModalVisible
			? <div className="spinner-overlay d-flex flex-column align-items-center justify-content-center h-100">
				<ZoneModal
					coords={coords}
					close={closeModal}
					onSave={closeModalAndResetZones}
				/>
			</div>
			: null}
	</div>
}

export default FlightsMap
