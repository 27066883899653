import { useEffect, useMemo } from 'react'
import { createPostHook, useApi } from './api'
import { useDbEntry } from './indexedDB'

export const useZones = (deps = []) => useApi(() => ({
	url: 'zone/list',
	mapResponse: (response) => response.data.map(({
		id,
		name,
		coords
	}) => ({
		id: String(id),
		name,
		coords
	}))
}), deps)
// TODO: DB-based fallback
// useDbOperation((db) =>
// 	db.transaction('zones', 'readonly').objectStore('zones').getAll(), deps)

export const useZonesCreate = createPostHook((body) => ({
	url: 'zone/create',
	body
}))

// TODO: DB-based fallback
// const [state, setState] = useState('pending') // 'progress', 'done', 'error'
//
// const {
// 	put,
// 	state
// } = useDbPut('zones')
//
// const post = ({
// 		title,
// 		description,
// 		coords
// 	}) => put({
// 		id: 'local-' + crypto.randomUUID(),
// 		title,
// 		description,
// 		coords
// 	})
//
// return {
// 	post,
// 	state
// }

export const useFlights = (coords) => useApi(() => {
	return coords.length <= 2
		? null
		: {
			method: 'POST',
			url: 'flight/find_by_polygon',
			body: {
				coords
			},
			mapResponse: (response) => response.data.map(({
				id,
				name
			}) => ({
				id: String(id),
				name
			}))
		}
}, [coords])

const useLocalBackup = (useLocalResult, useRemoteResult) => (id, ...rest) => {
	const [localId, remoteId] = id == null
		? [null, null]
		: id.startsWith('local-')
			? [id.substring(6), null]
			: [null, id]

	const local = useLocalResult(localId)
	const back = useRemoteResult(remoteId, ...rest)

	return back ?? local
}

export const useFrames = useLocalBackup(
	(id) => useDbEntry('frames', id).result?.frames,
	(id) => useApi(() => {
		return id == null
			? null
			: {
				url: `frame/find_by_flight_id/${id}`,
				mapResponse: (response) => response.data.map((entry) => ({
					id: String(entry.id),
					approved: entry.approved,
					width: entry.frame_width,
					height: entry.frame_height,
					azimuth: entry.azimuth,
					latitude: entry.latitude,
					longitude: entry.longitude,
					flightId: id, // : entry.fly_id,
					imageName: entry.image_name,
					imageUrl: entry.image_url,
					location: entry.location,
					alt: entry.location_alt,
					scale: entry.scale,
					persisted: true
				}))
			}
	}, [id]).result
)

export const useFramesUpdate = createPostHook(({
	id,
	...body
}) => ({
	url: `frame/update/${id}`,
	body
}))

export const useTargets = useLocalBackup(
	(id) => useDbEntry('targets', id).result,
	(id, deps = [id]) => useApi(() => {
		return id == null
			? id
			: {
				url: `target/find_by_frame_id/${id}`,
				mapResponse: (response) => response.data.map((target) => ({
					id: String(target.id),
					frameId: target.frame_id,
					typeId: target.target_type_id,
					sideId: target.target_side_id,
					title: target.title,
					description: target.description,
					x: target.position_x,
					y: target.position_y,
					location: target.location
				}))
			}
	}, deps).result
)

export const useTargetsCreate = createPostHook((body) => ({
	url: 'target/create',
	body
}))

export const useImage = useLocalBackup(
	(id) => {
		const { result } = useDbEntry('images', id)

		const imageUrl = useMemo(() => {
			if (result == null) {
				return null
			}
			return URL.createObjectURL(result.image)
		}, [result])

		useEffect((imageUrl) => {
			if (imageUrl != null) {
				return () => {
					URL.revokeObjectURL(imageUrl)
				}
			}
		}, [imageUrl])

		return imageUrl
	},
	(id) => useApi(() => {
		return id == null
			? null
			: {
				url: `storage/frame/${id}`,
				mapResponse: (response) => response.image
			}
	}, [id]).result
)

export const useTargetDetection = (frame) => useApi(() => {
	return frame == null || !frame.persisted
		? null
		: {
			method: 'POST',
			url: `detection/detect/${frame.id}`
		}
}, [frame])
