import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { dbName } from './config'
import DataHandler from './data/DataHandler'
import dbVersions from './data/dbVersions'
import { initDb } from './data/indexedDB'
import Flight from './flights/flight/Flight'
import Flights from './flights/Flights'
import LoginHandler from './login/LoginHandler'
import { flight, flightFrame, flights } from './routing'

const router = createBrowserRouter([{
// 	path: '/',
// 	element: <div className="w-100">
// 		<Link to={flights()}>
// 			flights
// 		</Link>
// 	</div>
// }, {
	path: flight(':flightId?'),
	element: <Flights/>
}, {
	path: `${flightFrame(':flightId', ':frameId?')}/:page?/:targetId?`,
	element: <Flight/>
}, {
	path: '*',
	element: <Navigate to={flights()}/>
}])

// TODO: should be done inside the component with useMemo, but for some reason is called twice
const dbPromise = initDb(dbName, dbVersions)

const App = () =>
	<LoginHandler>
		<DataHandler
			router={router}
			dbPromise={dbPromise}
		>
			<RouterProvider router={router}/>
		</DataHandler>
	</LoginHandler>

export default App
