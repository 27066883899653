import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import DraggableImage from '../../../../common/draggable/DraggableImage'
import ImageMarker from '../../../../common/draggable/ImageMarker'
import { useDraggableImageHandlers } from '../../../../common/draggable/util'
import { createMarkerIcon, scaleFromZoom, useMap, zoomFromScale } from '../../../../common/map/util'
import { useGoogleApi } from '../../../../common/mapLoader'
import Spinner from '../../../../common/spinner/Spinner'
import { useFramesUpdate } from '../../../../data/dataAccessors'
import '../style.css'

const radToDeg = 180 / Math.PI

const Approval = ({
	backLink,
	frame,
	image
}) => {
	const mapRef = useRef()
	const visibilityRef = useRef()
	const navigate = useNavigate()
	const coreApi = useGoogleApi('core')
	const mapsApi = useGoogleApi('maps')
	const markerApi = useGoogleApi('marker')
	const geometryApi = useGoogleApi('geometry')
	const [mapA, setMapA] = useState(null)
	const [mapB, setMapB] = useState(null)
	const [frameA, setFrameA] = useState(null)
	const [frameB, setFrameB] = useState(null)
	const [align, setAlign] = useState(false)
	const [dragged, setDragged] = useState(false)
	const [draggedPoint, setDraggedPoint] = useState(null)
	const {
		post,
		state
	} = useFramesUpdate()

	const map = useMap(mapRef)

	const drawPointOnMap = useCallback((position, color, update) => {
		if (map == null || markerApi == null || position == null) {
			return
		}

		const marker = new markerApi.Marker({
			position,
			icon: createMarkerIcon(coreApi, color),
			draggable: true,
			zIndex: 3,
			map
		})

		marker.addListener('dragend', () => {
			update(marker.position)
		})

		return () => {
			marker.setMap(null)
		}
	}, [map, markerApi])

	useEffect(() => {
		if (coreApi == null || map == null) {
			return
		}

		map.addListener('dragstart', () => {
			setDragged(true)
		})

		map.addListener('dragend', () => {
			setDragged(false)
		})

		return () => {
			coreApi.event.clearListeners(map, 'dragstart')
			coreApi.event.clearListeners(map, 'dragend')
		}
	}, [coreApi, map])

	useEffect(() => {
		if (coreApi == null || map == null) {
			return
		}

		map.addListener('click', ({ latLng }) => {
			if (mapA == null) {
				setMapA(latLng)
			} else if (mapB == null) {
				setMapB(latLng)
			}
		})

		return () => {
			coreApi.event.clearListeners(map, 'click')
		}
	}, [coreApi, map, mapA, mapB])

	useEffect(() => drawPointOnMap(mapA, 'red', setMapA), [drawPointOnMap, mapA])
	useEffect(() => drawPointOnMap(mapB, 'lime', setMapB), [drawPointOnMap, mapB])

	// useEffect(() => {
	// 	if (map == null || mapsApi == null || mapA == null || mapB == null) {
	// 		return
	// 	}
	//
	// 	const line = new mapsApi.Polyline({
	// 		path: [
	// 			mapA,
	// 			mapB
	// 		],
	// 		geodesic: true,
	// 		strokeColor: 'red',
	// 		strokeOpacity: 1.0,
	// 		strokeWeight: 2,
	// 		map
	// 	})
	//
	// 	return () => {
	// 		line.setMap(null)
	// 	}
	// }, [map, mapsApi])

	const onFrameClick = useCallback((position) => {
		if (frameA == null) {
			setFrameA(position)
		} else if (frameB == null) {
			setFrameB(position)
		}
		// this.canvasDrawLine(this.imagePoints.a.x, this.imagePoints.a.y, this.imagePoints.b.x, this.imagePoints.b.y, COLOR_GREEN)
		// this.canvasDrawLine(this.imagePoints.a.x, 0, this.imagePoints.a.x, this.storage.frameActive.size.height, COLOR_BLUE)
		// this.canvasDrawLine(0, this.imagePoints.a.y, this.storage.frameActive.size.width, this.imagePoints.a.y, COLOR_RED)
		// this.canvasDrawLine(this.imagePoints.b.x, this.imagePoints.b.y, this.imagePoints.b.x, this.imagePoints.a.y, COLOR_GREEN)
	}, [frameA, frameB])

	const onFrameMove = useCallback((event, position) => {
		if (draggedPoint == null) {
			return false
		}
		if (event.buttons === 0) {
			setDraggedPoint(null)
		} else if (draggedPoint === 'a') {
			setFrameA(position)
		} else if (draggedPoint === 'b') {
			setFrameB(position)
		}
		return true
	}, [draggedPoint])

	const onFrameUp = useCallback(() => {
		setDraggedPoint(null)
	}, [])

	const {
		zoom,
		setZoom,
		offset,
		setOffset,
		dragPosition,
		frameRef,
		onClick,
		onMouseDown,
		onMouseUp,
		onMouseMove,
		onWheelCapture
	} = useDraggableImageHandlers(onFrameClick, onFrameMove, onFrameUp)

	const [scale, heading, center] = useMemo(() => {
		if (frame == null || mapA == null || mapB == null || frameA == null || frameB == null || geometryApi == null) {
			return []
		}

		const scale = geometryApi.spherical.computeDistanceBetween(mapA, mapB) / Math.hypot(frameA.y - frameB.y, frameA.x - frameB.x)
		const heading = geometryApi.spherical.computeHeading(mapA, mapB) - Math.atan2(frameA.y - frameB.y, frameA.x - frameB.x) * radToDeg + 90

		const centerX = frame.width / 2
		const centerY = frame.height / 2

		// 	this.drawPointImage('C', centerImagePoint.x, centerImagePoint.y, COLOR_YELLOW)
		// 	this.canvasDrawLine(0, centerImagePoint.y, this.storage.frameActive.size.width, centerImagePoint.y, COLOR_YELLOW)
		// 	this.canvasDrawLine(this.storage.frameActive.size.width / 2, 0, this.storage.frameActive.size.width / 2, this.storage.frameActive.size.height, COLOR_YELLOW)

		const distanceA = Math.hypot(frameA.y - centerY, frameA.x - centerX) * scale
		const headingA = Math.atan2(frameA.y - centerY, frameA.x - centerX) * radToDeg + heading - 90
		const center = geometryApi.spherical.computeOffset(mapA, distanceA, headingA)

		// 	this.canvasDrawLine(this.imagePoints.a.x, this.imagePoints.a.y, this.storage.frameActive.size.width / 2, this.storage.frameActive.size.height / 2, COLOR_BLUE)

		return [scale, heading, center]
	}, [frame, mapA, mapB, frameA, frameB, geometryApi])

	useEffect(() => {
		if (!align || map == null || coreApi == null || geometryApi == null) {
			return
		}

		map.addListener('center_changed', () => {
			const newCenter = map.getCenter()
			const newRange = geometryApi.spherical.computeDistanceBetween(center, newCenter) / scale
			const newAngle = (geometryApi.spherical.computeHeading(center, newCenter) - heading + 90) / radToDeg

			const oldRange = Math.hypot(offset.y, offset.x)
			const oldAngle = Math.atan2(offset.y, offset.x)

			const angleDiff = Math.abs(oldAngle - newAngle) % Math.PI

			// prevent infinite loop caused by rounding errors
			if ((angleDiff > 0.0001 && (Math.PI - angleDiff) > 0.0001) ||
				Math.abs(Math.log(oldRange) - Math.log(newRange)) > 0.001) {
				setOffset({
					x: Math.cos(newAngle) * newRange,
					y: Math.sin(newAngle) * newRange
				})
			}
		})

		map.addListener('zoom_changed', () => {
			const newZoom = scale / scaleFromZoom(map.getZoom(), map.getCenter().lat())
			// prevent infinite loop caused by rounding errors
			if (Math.abs(Math.log(zoom) - Math.log(newZoom)) > 0.001) {
				setZoom(newZoom)
			}
		})

		return () => {
			coreApi.event.clearListeners(map, 'center_changed')
			coreApi.event.clearListeners(map, 'zoom_changed')
		}
	}, [map, align, offset, zoom, scale, center, heading, coreApi, geometryApi])

	useEffect(() => drawPointOnMap(center, 'blue', setMapB), [drawPointOnMap, center])

	useEffect(() => {
		if (frame == null || scale == null || center == null || heading == null || mapsApi == null || geometryApi == null) {
			return
		}

		const distance = Math.hypot(frame.height, frame.width) * scale / 2
		const angle = Math.atan2(frame.height, frame.width) * radToDeg - 90

		const points = [
			geometryApi.spherical.computeOffset(center, distance, angle + heading),
			geometryApi.spherical.computeOffset(center, distance, -angle + heading),
			geometryApi.spherical.computeOffset(center, distance, 180 + angle + heading),
			geometryApi.spherical.computeOffset(center, distance, 180 - angle + heading)
		]

		points.push(points[0])

		const polygon = new mapsApi.Polygon({
			paths: points,
			strokeColor: '#abfc1c',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#abfc1c',
			fillOpacity: 0.05,
			zIndex: 2,
			map
		})

		return () => {
			polygon.setMap(null)
		}
	}, [frame, scale, center, heading, mapsApi, geometryApi])

	useEffect(() => {
		if (align || frame == null || scale == null || center == null || heading == null || mapsApi == null || geometryApi == null) {
			return
		}

		const rect = visibilityRef.current.getBoundingClientRect()

		const left = offset.x + rect.width / 2 / zoom
		const top = offset.y - rect.height / 2 / zoom
		const right = offset.x - rect.width / 2 / zoom
		const bottom = offset.y + rect.height / 2 / zoom

		const points = [
			geometryApi.spherical.computeOffset(center, Math.hypot(top, left) * scale, Math.atan2(top, left) * radToDeg - 90 + heading),
			geometryApi.spherical.computeOffset(center, Math.hypot(bottom, left) * scale, Math.atan2(bottom, left) * radToDeg - 90 + heading),
			geometryApi.spherical.computeOffset(center, Math.hypot(bottom, right) * scale, Math.atan2(bottom, right) * radToDeg - 90 + heading),
			geometryApi.spherical.computeOffset(center, Math.hypot(top, right) * scale, Math.atan2(top, right) * radToDeg - 90 + heading)
		]

		points.push(points[0])

		const polygon = new mapsApi.Polygon({
			paths: points,
			strokeColor: 'blue',
			strokeOpacity: 0.4,
			strokeWeight: 2,
			fillColor: 'blue',
			fillOpacity: 0.1,
			zIndex: 1,
			map
		})

		return () => {
			polygon.setMap(null)
		}
	}, [frame, align, offset, zoom, scale, center, heading, mapsApi, geometryApi])

	useEffect(() => {
		if (coreApi == null || map == null || frame == null) {
			return
		}

		map.moveCamera({
			center: new coreApi.LatLng(
				frame.location.lat,
				frame.location.lng
			),
			heading: frame.azimuth,
			zoom: 18
		})
	}, [coreApi, map, frame])

	useEffect(() => {
		if (dragged || map == null || center == null || scale == null || heading == null || geometryApi == null) {
			return
		}

		if (align) {
			map.setTiltInteractionEnabled(false)
			map.setHeadingInteractionEnabled(false)

			map.moveCamera({
				center: geometryApi.spherical.computeOffset(center, Math.hypot(offset.y, offset.x) * scale, Math.atan2(offset.y, offset.x) * radToDeg - 90 + heading),
				heading,
				zoom: zoomFromScale(scale / zoom, center.lat()),
				tilt: 0
			})
		} else {
			map.setTiltInteractionEnabled(true)
			map.setHeadingInteractionEnabled(true)
		}
	}, [map, dragged, align, offset, zoom, scale, center, heading, geometryApi])

	const save = useCallback(() => {
		post({
			id: frame.id,
			azimuth: heading,
			approved: 1,
			scale,
			// polygon: this.polygon, should not be necessary
			location: center
		})
	}, [frame, heading, scale, center])

	useEffect(() => {
		if (state === 'done') {
			navigate(backLink)
		}
	}, [state])

	const markers = useMemo(() => {
		const markers = []

		if (frameA != null) {
			markers.push(<ImageMarker
				key="a"
				point={frameA}
				color="red"
				dragged={draggedPoint === 'a'}
				onMouseDown={() => setDraggedPoint('a')}
			/>)
		}

		if (frameB != null) {
			markers.push(<ImageMarker
				key="b"
				point={frameB}
				color="lime"
				dragged={draggedPoint === 'b'}
				onMouseDown={() => setDraggedPoint('b')}
			/>)
		}

		if (frame != null) {
			markers.push(<ImageMarker
				key="center"
				point={{
					x: frame.width / 2,
					y: frame.height / 2
				}}
				color="blue"
			/>)
		}
		return markers
	}, [zoom, frame, frameA, frameB, draggedPoint])

	return <div
		className="flex-grow-1 d-flex flex-column overflow-hidden position-relative"
		onMouseMove={onMouseMove}
		onMouseUp={onMouseUp}
	>
		<div className="flex-size-1 d-flex overflow-hidden">
			<div
				className="flex-size-1 d-flex overflow-hidden"
				ref={mapRef}
			/>
			<div className="flex-size-1 d-flex overflow-hidden position-relative">
				{frame == null || image == null
					? null
					: <DraggableImage
						parentRef={visibilityRef}
						frameRef={frameRef}
						src={image}
						width={frame.width}
						height={frame.height}
						zoom={zoom}
						offset={offset}
						dragged={dragged || dragPosition != null}
						onClick={onClick}
						onMouseDown={onMouseDown}
						onWheelCapture={onWheelCapture}
					>
						{markers}
					</DraggableImage>}
				<div className="navigation-menu bg-body-tertiary">
					<div
						className="icon-button"
						title="Збільшити"
						onClick={() => setZoom(zoom * 1.5)}
					>
						<FontAwesomeIcon icon={faSearchPlus}/>
					</div>
					<div
						className="icon-button"
						title="Зменшити"
						onClick={() => setZoom(zoom / 1.5)}
					>
						<FontAwesomeIcon icon={faSearchMinus}/>
					</div>
				</div>
			</div>
		</div>
		<div className="d-flex p-1 align-items-center">
			<div className="text-white flex-grow-1 p-3">
				{
					mapA == null
						? 'Оберіть точку A на мапі'
						: mapB == null
							? 'Оберіть точку B на мапі'
							: frameA == null
								? 'Оберіть точку A на зображенні'
								: frameB == null
									? 'Оберіть точку B на зображенні'
									: <>
										<label
											htmlFor="align"
											className="pe-2"
										>
											Вирівняти
										</label>
										<input
											id="align"
											type="checkbox"
											value={align}
											onChange={(event) => {
												setAlign(event.currentTarget.checked)
											}}
										/>
									</>
				}
			</div>
			<NavLink
				className="btn btn-secondary m-1"
				to={backLink}
			>
				Скасувати
			</NavLink>
			<button
				className="btn btn-success m-1"
				disabled={frame == null || scale == null || center == null || heading == null}
				onClick={save}
			>
				Прив'язати
			</button>
		</div>
		{state === 'progress' || state === 'done'
			? <Spinner
				className="absolute-spinner"
			/>
			: null}
	</div>
}

export default Approval
